<template>
  <div>
    <div class="tway-title tway-title-h2 text--tway-violet">Mi Perfil</div>

    <div class="mb-5">
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="3">
            <div class="tway-title tway-label text--tway-violet">Nombre</div>
          </v-col>
          <v-col>
            <v-text-field v-model="user.firstName" :rules="[required('Nombre')]" required outlined dense />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div class="tway-title tway-label text--tway-violet">Apellido</div>
          </v-col>
          <v-col>
            <v-text-field v-model="user.lastName" :rules="[required('Apellido')]" required outlined dense />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div class="tway-title tway-label text--tway-violet">E-mail</div>
          </v-col>
          <v-col>
            {{ user.username }}
          </v-col>
        </v-row>
        <v-row>
          <v-btn depressed rounded color="secondary" :to="{ name: 'my-profile' }"> Cancelar </v-btn>

          <v-spacer />

          <v-btn depressed rounded color="primary" @click="updateUserData"> Actualizar </v-btn>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import ValidatorsService from "@/services/validatorsService";
import currentUserService from "@/services/currentUserService";

export default {
  data: () => ({
    valid: true,
    user: {
      firstName: "",
      lastName: "",
      username: "",
    },
  }),

  created() {
    this.getCurrentUserData();
  },

  methods: {
    required: (propertyType) => ValidatorsService.required(propertyType),

    getCurrentUserData() {
      currentUserService
        .currentUserData()
        .then((userData) => (this.user = userData))
        .catch((err) => this.$log.error(err));
    },

    updateUserData() {
      if (this.$refs.form.validate()) {
        currentUserService
          .updateUserData(this.user)
          .then(() => this.$router.push({ name: "my-profile" }))
          .catch((err) => this.$log.error(err));
      }
    },
  },
};
</script>
